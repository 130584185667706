<template>
  <div>
    <Breadcrumb :items="breadcrumb" />
    <v-container fluid class="pa-0">
      <v-row no-gutters v-if="cooperative">
        <v-col cols="12" class="cooperative-banner" v-if="isValid">
          <img v-if="imgSrc" :src="imgSrc" alt="" />
          <v-spacer v-else class="py-12"></v-spacer>
          <h1
            v-html="cooperative.title"
            class="cooperative-banner--title white--text"
          ></h1>
          <div class="cooperative-banner_content w-100 d-flex flex-column">
            <v-row class="justify-md-space-between justity-center">
              <v-col
                cols="12"
                md="3"
                class="rounded-md grey pa-4 mb-3 d-flex align-center"
              >
                <v-icon class="mr-3" large color="primary">$location</v-icon>
                <div class="d-flex flex-column">
                  <h5 class="mb-1 font-weight-bold text-uppercase">
                    {{ $t("cooperatives.cooperative.address") }}
                  </h5>
                  <span>
                    {{ $ebsn.meta(cooperative, "articletype.ADDRESS") }}</span
                  >
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="rounded-md grey pa-4 mb-3 d-flex align-center"
              >
                <v-icon class="mr-3" large color="primary">$phone</v-icon>
                <div class="d-flex flex-column">
                  <h5 class="mb-1 font-weight-bold text-uppercase">
                    {{ $t("cooperatives.cooperative.phone") }}
                  </h5>
                  <span>
                    {{ $ebsn.meta(cooperative, "articletype.PHONE") }}</span
                  >
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="rounded-md grey pa-4 mb-3 d-flex align-center"
              >
                <v-icon class="mr-3" large fill="primary" color="primary"
                  >$mail</v-icon
                >
                <div class="d-flex flex-column">
                  <h5 class="mb-1 font-weight-bold text-uppercase">
                    {{ $t("cooperatives.cooperative.emailAndSite") }}
                  </h5>
                  <div class="d-flex flex-column">
                    <a
                      :aria-label="
                        `Scrivi una mail a ${$ebsn.meta(
                          cooperative,
                          'articletype.EMAIL'
                        )}`
                      "
                      :title="
                        `Scrivi una mail a ${$ebsn.meta(
                          cooperative,
                          'articletype.EMAIL'
                        )}`
                      "
                      class="text-caption default--text text--lighten-1 text-decoration-none"
                      :href="
                        'mailto:' + $ebsn.meta(cooperative, 'articletype.EMAIL')
                      "
                    >
                      {{ $ebsn.meta(cooperative, "articletype.EMAIL") }}
                    </a>

                    <a
                      target="_blank"
                      :aria-label="
                        `Vai alla  ${$ebsn.meta(
                          cooperative,
                          'articletype.EMAIL'
                        )}`
                      "
                      class="text-caption default--text text--lighten-1 text-decoration-none"
                      :href="$ebsn.meta(cooperative, 'articletype.WEBSITE')"
                    >
                      {{ $ebsn.meta(cooperative, "articletype.WEBSITE_NAME") }}
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="cooperative-detail__body"
          v-if="cooperative.content"
        >
          <p v-html="cooperative.content"></p>
        </v-col>
        <v-col cols="12" class="px-8">
          <h2>
            {{
              `${$t("cooperatives.cooperative.stores")} ${cooperative.title}`
            }}
          </h2>
          <v-row>
            <v-col
              cols="12"
              sm="5"
              md="3"
              v-for="pdv in pdvLists"
              :key="pdv.warehouseId"
            >
              <div
                class="fill-height pa-3 d-flex flex-column align-start pdv-container rounded-md"
              >
                <img
                  contain
                  style="width: 90px; height: auto;"
                  :src="
                    `/img_layout/warehouse/logo-${language}/${$ebsn.meta(
                      pdv,
                      'warehouse_detail.LOGO',
                      null
                    )}.png`
                  "
                />
                <h5 class="font-weight-bold">{{ pdv.name }}</h5>
                <span style="min-height: 50px;">
                  {{ pdv.address.address1 }}, {{ pdv.address.addressNumber }},{{
                    pdv.address.city
                  }},{{ pdv.address.province }},{{
                    pdv.address.postalcode
                  }}</span
                >
                <v-btn
                  icon
                  class="mt-auto ml-auto"
                  :to="`/negozi-e-volantini/${pdv.slug}`"
                >
                  <v-icon large color="primary">$arrowForward</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="px-8 my-5">
          <a
            target="_self"
            class="ml-auto d-flex align-center 
           text-decoration-none"
            href="/le-cooperative"
          >
            <v-icon class="mr-3" color="primary" large>$arrowBack</v-icon>
            <strong color="default" class="default--text">{{
              $t("cooperatives.goBack")
            }}</strong>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.cooperative-banner {
  position: relative;
  img {
    width: 100%;
    height: 95%;
  }
  &--title {
    position: absolute;
    top: 50%;
    left: 5%;
    right: auto;
  }
  &_content {
    position: absolute;
    bottom: 10%;
    padding: 0 5%;
  }
}
.cooperative-detail__header,
.cooperative-detail__body {
  .image-col {
    border-radius: 30px !important;
  }
}
.cooperative-detail {
  &__header {
    position: relative;
    //border: 3px solid red;
    &--cards {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.pdv-container {
  border: 1px solid #d4d4d4 !important;
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import AddressService from "@/commons/service/addressService";
import mksService from "@/commons/service/mksCmService";
import article from "~/mixins/article";
import { mapGetters } from "vuex";
export default {
  name: "CooperativeDetail",
  components: { Breadcrumb },
  mixins: [article],
  data() {
    return {
      cooperativeSlug: null,
      pdvLists: null,
      // cooperativeId: null,
      cooperative: null
    };
  },
  computed: {
    ...mapGetters({
      language: "app/getLanguage"
    }),
    imgSrc() {
      return this.cooperative?.media?.length > 0
        ? this.cooperative.media[0].large
        : null;
    },
    isValid() {
      return (
        this.cooperative?.metaData?.articletype?.ADDRESS?.trim().length > 0 &&
        this.cooperative?.metaData?.articletype?.PHONE?.trim().length > 0 &&
        (this.cooperative?.metaData?.articletype?.EMAIL?.trim().length > 0 ||
          this.cooperative?.metaData?.articletype?.WEBSITE_NAME?.trim().length >
            0)
      );
    }
  },
  methods: {
    async getCooperativeDetail() {
      try {
        let result = await mksService.getArticle({
          slug: this.cooperativeSlug
        });
        if (result) {
          console.log("result", result);
          this.cooperative = result.data;
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.general")
        });
        return null;
      }
    },
    async findWarehouse() {
      let searchParams = {
        parent_company_id: this.$ebsn.meta(
          this.cooperative,
          "articletype.COMPANY_ID"
        )
      };

      let data = await AddressService.findWarehouse(searchParams);
      console.log("DATA", data);
      this.pdvLists = data.warehouses;
    }
  },
  async mounted() {
    console.log("Route params:", this.$route.params);
    this.cooperativeSlug = this.$route.params.slug;
    await this.getCooperativeDetail();
    await this.findWarehouse();
  },
  watch: {
    "$route.query.cooperativeSlug"(value) {
      this.cooperativeSlug = value;
      this.getCooperativeDetail();
    }
  }
};
</script>
